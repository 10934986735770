import { Routes } from 'constants/routes.constants';
import { Href } from 'types/api.types';

import { IconNames } from 'components/@icons/Icon';

export enum Modules {
  CompanyNews = 'module_companynews',
  Search = 'module_search',
  UserDetail = 'module_userdetail',
  Chat = 'module_chat',
  Settings = 'module_settings',
  Sites = 'module_sites',
  Bookmarks = 'module_bookmarks',
  People = 'module_people',
  Forms = 'module_forms',
  Emergency = 'module_emergency',
  Tasks = 'module_delhaizemyday',
  Absences = 'module_absences',
  Illness = 'module_illness',
  Shift = 'module_shift',
  ShiftV3 = 'module_shift_v3',
  TaskAssistant = 'module_taskassistant',
  Team = 'module_team',
  DocumentLibrary = 'module_documentlibrary',
  LinkLibrary = 'module_linklibrary',
  Meetings = 'module_meetings',
  Surveys = 'module_surveys',
  SurveysEdit = 'module_surveys_edit',
  Messaging = 'module_messaging',
  ExtensionFramework = 'module_extension_framework',
  LunchMenu = 'module_lunchmenu',
  OrganizationalChart = 'module_organizationalchart',
  Weather = 'module_weather',
  ProximusSettings = 'module_proximussettings',
  Sdworx = 'module_sdworx',
  Doccle = 'module_doccle',
  ChatLayer = 'module_chat_layer',
  Workflows = 'module_workflows',
  Events = 'module_events',
  Pixieset = 'module_pixieset',
  Epromote = 'module_epromote',
  Coins = 'module_coins',
  Dialoog = 'module_dialoog',
  Sabacloud = 'module_sabacloud',
  BeaulieuBighome = 'module_beaulieu_bighome',
  BelgoCatering = 'module_belgocatering',
  LunchDeloitte = 'module_lunch_deloitte',
  Cardstoconnect = 'module_cardstoconnect',
  BeaulieuSportswear = 'module_beaulieu_sportswear',
  BeaulieuStepchallenge = 'module_beaulieu_stepchallenge',
  SelfserviceFaq = 'module_selfservice_faq',
  IframeDemoOne = 'module_iframe_demo_one',
  IframeDemoTwo = 'module_iframe_demo_two',
  IframeDemoThree = 'module_iframe_demo_three',
  SocialWallOne = 'module_social_wall_one',
  SocialWallTwo = 'module_social_wall_two',
  Workelo = 'module_workelo',
  WorkeloOne = 'module_workelo_one',
  WorkeloTwo = 'module_workelo_two',
  TallyForm = 'module_tally_form',
  InternalBrwoser = 'module_internal_browser',
  Summary = 'module_summary',
}

export const webModules: Array<WebModuleConfig> = [
  {
    ids: [Modules.CompanyNews],
    label: 'module_companynews_menu_item_overview',
    to: Routes.News,
    startIcon: 'IcoNews',
    showInMobileMenu: false,
    fetched: false,
  },
  {
    ids: [Modules.Tasks],
    label: 'module_delhaizemyday_menu_item_overview',
    to: Routes.Tasks,
    showInMobileMenu: true,
    startIcon: 'IcoTasks',
    fetched: false,
  },
  {
    ids: [Modules.People, Modules.Sites],
    label: 'core_menu_item_organisation',
    to: Routes.Organisation,
    startIcon: 'IcoOrganigram',
    showInMobileMenu: true,
    fetched: false,
  },
  {
    ids: [Modules.DocumentLibrary],
    label: 'module_documentlibrary_overview_title',
    to: Routes.Documents,
    startIcon: 'IcoDocuments',
    showInMobileMenu: true,
    fetched: false,
  },
  {
    ids: [Modules.LinkLibrary],
    label: 'module_linklibrary_menu_item_overview',
    to: Routes.Links,
    startIcon: 'IcoLinks',
    showInMobileMenu: true,
    fetched: false,
  },
  {
    ids: [Modules.Forms],
    label: 'module_forms_display_name',
    to: Routes.Forms,
    startIcon: 'IcoForm',
    showInMobileMenu: true,
    fetched: false,
  },
];

export const iframeModules: Array<IframeModuleConfig> = (
  [
    {
      id: Modules.Pixieset,
      name: 'module_photogallery_menu_item_overview',
      endpoint: '/pixieset/iframe',
      path: '/pixieset',
      iconName: 'IcoImage',
    },
    {
      id: Modules.Events,
      name: 'module_events_menu_item_overview',
      endpoint: '/events/iframe',
      path: '/events',
      iconName: 'IcoCalendar',
    },
    {
      id: Modules.Doccle,
      name: 'module_doccle_menu_item_overview',
      endpoint: '/doccle/iframe',
      path: '/doccle',
      iconName: 'IcoDoccle',
      customRender: (src: string) => (
        <iframe
          data-testid="docclePlugin"
          title="test"
          width="100%"
          height="100%"
          id="doccle-receiver-plugin"
          sandbox="allow-popups allow-same-origin allow-scripts allow-top-navigation allow-forms allow-popups-to-escape-sandbox allow-downloads"
          src={src}
        />
      ),
    },
    {
      id: Modules.ChatLayer,
      name: 'module_chat_layer_menu_item_overview',
      endpoint: '/chat-layer/iframe',
      path: '/campfire',
      iconName: 'IcoCampfire',
      customRender: (src: string) => (
        <iframe
          title="campfire"
          style={{ width: '100%', height: '100%' }}
          src={src}
          sandbox="allow-scripts allow-same-origin"
        />
      ),
    },
    {
      id: Modules.Epromote,
      name: 'module_epromote_menu_item_overview',
      endpoint: '/e-promote/iframe',
      path: '/epromote',
      iconName: 'IcoEpromote',
    },
    {
      id: Modules.Coins,
      iconName: 'IcoCoins',
      name: 'module_coins_overview_title',
      path: '/coins',
      endpoint: '/coins/iframe',
    },
    {
      id: Modules.Dialoog,
      iconName: 'IcoContinueDialoog',
      name: 'module_dialoog_overview_title',
      path: '/dialoog',
      endpoint: '/dialoog/iframe',
      customRender: (src: string) => (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <iframe src={src} style={{ flex: 1 }} />
        </div>
      ),
    },
    {
      id: Modules.Sabacloud,
      iconName: 'IcoEducation',
      name: 'module_sabacloud_overview_title',
      path: '/sabacloud',
      endpoint: '/sabacloud/iframe',
    },
    {
      id: Modules.BeaulieuBighome,
      iconName: 'IcoBeaulieuBighome',
      name: 'module_beaulieu_bighome_overview_title',
      path: '/bighome',
      endpoint: '/bighome/iframe',
    },
    {
      id: Modules.BelgoCatering,
      iconName: 'IcoLunch',
      name: 'module_belgocatering_overview_title',
      path: '/belgocatering',
      endpoint: '/belgocatering/iframe',
    },
    {
      id: Modules.LunchDeloitte,
      iconName: 'IcoLunchMenu',
      name: 'module_lunch_deloitte_overview_title',
      path: '/lunchmenu',
      endpoint: '/lunchmenu/iframe',
    },
    {
      id: Modules.Cardstoconnect,
      iconName: 'IcoDuplicate',
      name: 'module_cardstoconnect_overview_title',
      path: '/cardstoconnect',
      endpoint: '/cardstoconnect/iframe',
    },
    {
      id: Modules.BeaulieuSportswear,
      iconName: 'IcoShirt',
      name: 'module_beaulieu_sportswear_overview_title',
      path: '/sportswear',
      endpoint: '/sportswear/iframe',
    },
    {
      id: Modules.BeaulieuStepchallenge,
      iconName: 'IcoShoeRun',
      name: 'module_beaulieu_stepchallenge_overview_title',
      path: '/stepchallenge',
      endpoint: '/stepchallenge/iframe',
    },
    {
      id: Modules.SelfserviceFaq,
      iconName: 'IcoDocumentHelp',
      name: 'module_selfservice_faq_overview_title',
      path: '/yource-selfservice',
      endpoint: '/yource-selfservice/iframe',
    },
    {
      id: Modules.IframeDemoOne,
      iconName: 'IcoWorkelo',
      name: 'module_iframe_demo_one_overview_title',
      path: '/module_iframe_demo_one',
      endpoint: '/iframe_demo_one/iframe',
      errorMessage: 'module_iframe_demo_one_overview_error_message',
      customRender: (src: string) => (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <iframe src={src} style={{ flex: 1 }} />
        </div>
      ),
    },
    {
      id: Modules.IframeDemoTwo,
      iconName: 'IcoImage',
      name: 'module_iframe_demo_two_overview_title',
      path: '/module_iframe_demo_two',
      endpoint: '/iframe_demo_two/iframe',
    },
    {
      id: Modules.IframeDemoThree,
      iconName: 'IcoEducation',
      name: 'module_iframe_demo_three_overview_title',
      path: '/module_iframe_demo_three',
      endpoint: '/iframe_demo_three/iframe',
    },
    {
      id: Modules.SocialWallOne,
      iconName: 'IcoImage',
      name: 'module_social_wall_one_overview_title',
      path: '/module_social_wall_one',
      endpoint: '/module_social_wall_one/iframe',
    },
    {
      id: Modules.SocialWallTwo,
      iconName: 'IcoImage',
      name: 'module_social_wall_two_overview_title',
      path: '/module_social_wall_two',
      endpoint: '/module_social_wall_two/iframe',
    },
    {
      id: Modules.WorkeloOne,
      iconName: 'IcoWorkelo',
      name: 'module_workelo_overview_title',
      path: '/module_workelo_one',
      endpoint: '/module_workelo_one/iframe',
      errorMessage: 'module_workelo_overview_error_message',
    },
    {
      id: Modules.WorkeloTwo,
      iconName: 'IcoWorkelo',
      name: 'module_workelo_overview_title',
      path: '/module_workelo_two',
      endpoint: '/module_workelo_two/iframe',
      errorMessage: 'module_workelo_overview_error_message',
    },
    {
      id: Modules.TallyForm,
      iconName: 'IcoSelecta',
      name: 'module_tally_overview_title',
      path: '/module_tally_form',
      endpoint: '/module_tally_form/iframe',
    },
  ] as Array<IframeModuleConfig>
).sort((a, b) => Object.values(Modules).indexOf(a.id) - Object.values(Modules).indexOf(b.id));

export type IframeModuleConfig = {
  id: Modules;
  name: string;
  endpoint: string;
  path: string;
  iconName: IconNames;
  errorMessage?: string;
  customRender?: (src?: string) => React.JSX.Element;
};

type WebModuleConfig =
  | { ids: Array<Modules>; Component: React.FunctionComponent }
  | {
      ids: Array<Modules>;
      label: string;
      to: string;
      startIcon?: IconNames;
      startImage?: Href;
      fetched: boolean;
      showInMobileMenu?: boolean;
    };
