import { InputAdornment } from '@mui/material';
import { useIntl } from 'react-intl';

import Icon from 'components/@icons';

interface Props {
  value?: string;
  onClear: () => void;
  onSearchClick?: () => void;
}

const SearchEndAdornment = ({ value, onClear, onSearchClick }: Props) => {
  const intl = useIntl();
  return (
    <>
      {!!value && (
        <InputAdornment
          className="clear-icon"
          position="end"
          sx={{ cursor: 'pointer', opacity: 0, visibility: 'hidden' }}
          aria-label={intl.formatMessage({ id: 'core_general_clear' })}
          onClick={onClear}
        >
          <Icon name="IcoCloseCircle" size={16} />
        </InputAdornment>
      )}

      <InputAdornment
        position="end"
        {...(onSearchClick && { onClick: onSearchClick })}
        sx={{ cursor: typeof onSearchClick === 'function' ? 'pointer' : 'default' }}
      >
        <Icon name="IcoSearch" />
      </InputAdornment>
    </>
  );
};

export default SearchEndAdornment;
