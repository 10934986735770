import { lazy } from 'react';

export default {
  IcoFaq: lazy(() => import('./IcoFaq')),
  IcoAccount: lazy(() => import('./IcoAccount')),
  IcoAddGroup: lazy(() => import('./IcoAddGroup')),
  IcoGroupsOfPeople: lazy(() => import('./IcoGroupsOfPeople')),
  IcoArrowSmallDown: lazy(() => import('./IcoArrowSmallDown')),
  IcoArrowSmallUp: lazy(() => import('./IcoArrowSmallUp')),
  IcoArrowBack: lazy(() => import('./IcoArrowBack')),
  IcoArrowForward: lazy(() => import('./IcoArrowForward')),
  IcoArrowSmallForward: lazy(() => import('./IcoArrowSmallForward')),
  IcoBeaulieuBighome: lazy(() => import('./IcoBeaulieuBighome')),
  IcoBirthday: lazy(() => import('./IcoBirthday')),
  IcoBookmarkFilled: lazy(() => import('./IcoBookmarkFilled')),
  IcoBookmarkOutlined: lazy(() => import('./IcoBookmarkOutlined')),
  IcoCalendar: lazy(() => import('./IcoCalendar')),
  IcoCampfire: lazy(() => import('./IcoCampfire')),
  IcoCheck: lazy(() => import('./IcoCheck')),
  IcoCloseCircle: lazy(() => import('./IcoCloseCircle')),
  IcoClose: lazy(() => import('./IcoClose')),
  IcoCoins: lazy(() => import('./IcoCoins')),
  IcoContinueDialoog: lazy(() => import('./IcoContinueDialoog')),
  IcoCookies: lazy(() => import('./IcoCookies')),
  IcoDoccle: lazy(() => import('./IcoDoccle')),
  IcoDocumentPdf: lazy(() => import('./IcoDocumentPdf')),
  IcoDocumentPresentation: lazy(() => import('./IcoDocumentPresentation')),
  IcoDocumentSpreadsheet: lazy(() => import('./IcoDocumentSpreadsheet')),
  IcoDocumentHelp: lazy(() => import('./IcoDocumentHelp')),
  IcoDocuments: lazy(() => import('./IcoDocuments')),
  IcoDownload: lazy(() => import('./IcoDownload')),
  IcoDuplicate: lazy(() => import('./IcoDuplicate')),
  IcoEdit: lazy(() => import('./IcoEdit')),
  IcoEducation: lazy(() => import('./IcoEducation')),
  IcoEpromote: lazy(() => import('./IcoEpromote')),
  IcoError: lazy(() => import('./IcoError')),
  IcoExternal: lazy(() => import('./IcoExternal')),
  IcoFeedback: lazy(() => import('./IcoFeedback')),
  IcoFile: lazy(() => import('./IcoFile')),
  IcoFlag: lazy(() => import('./IcoFlag')),
  IcoFolder: lazy(() => import('./IcoFolder')),
  IcoForm: lazy(() => import('./IcoForm')),
  IcoForum: lazy(() => import('./IcoForum')),
  IcoImage: lazy(() => import('./IcoImage')),
  IcoInfo: lazy(() => import('./IcoInfo')),
  IcoLink: lazy(() => import('./IcoLink')),
  IcoLinks: lazy(() => import('./IcoLinks')),
  IcoList: lazy(() => import('./IcoList')),
  IcoLunch: lazy(() => import('./IcoLunch')),
  IcoLunchMenu: lazy(() => import('./IcoLunchMenu')),
  IcoMail: lazy(() => import('./IcoMail')),
  IcoMap: lazy(() => import('./IcoMap')),
  IcoMore: lazy(() => import('./IcoMore')),
  IcoMoreHorizFilled: lazy(() => import('./IcoMoreHorizFilled')),
  IcoNews: lazy(() => import('./IcoNews')),
  IcoOffice: lazy(() => import('./IcoOffice')),
  IcoOrganigram: lazy(() => import('./IcoOrganigram')),
  IcoPhone: lazy(() => import('./IcoPhone')),
  IcoPlayCircle: lazy(() => import('./IcoPlayCircle')),
  IcoPlay: lazy(() => import('./IcoPlay')),
  IcoPlayVideo: lazy(() => import('./IcoPlayVideo')),
  IcoPrint: lazy(() => import('./IcoPrint')),
  IcoQuote: lazy(() => import('./IcoQuote')),
  IcoReactionCelebrate: lazy(() => import('./IcoReactionCelebrate')),
  IcoReactionCurious: lazy(() => import('./IcoReactionCurious')),
  IcoReactionInspired: lazy(() => import('./IcoReactionInspired')),
  IcoReactionLike: lazy(() => import('./IcoReactionLike')),
  IcoReactionLove: lazy(() => import('./IcoReactionLove')),
  IcoRefresh: lazy(() => import('./IcoRefresh')),
  IcoSearch: lazy(() => import('./IcoSearch')),
  IcoSecurity: lazy(() => import('./IcoSecurity')),
  IcoSelecta: lazy(() => import('./IcoSelecta')),
  IcoSettings: lazy(() => import('./IcoSettings')),
  IcoShirt: lazy(() => import('./IcoShirt')),
  IcoShoeRun: lazy(() => import('./IcoShoeRun')),
  IcoSurvey: lazy(() => import('./IcoSurvey')),
  IcoTasks: lazy(() => import('./IcoTasks')),
  IcoVideo: lazy(() => import('./IcoVideo')),
  IcoWarning: lazy(() => import('./IcoWarning')),
  IcoWorkelo: lazy(() => import('./IcoWorkelo')),
  IcoShare: lazy(() => import('./IcoShare')),
  IcoDelete: lazy(() => import('./IcoDelete')),
  IcoLogout: lazy(() => import('./IcoLogout')),
  IcoMessage: lazy(() => import('./IcoMessage')),
  IcoSend: lazy(() => import('./IcoSend')),
  IcoHamburger: lazy(() => import('./IcoHamburger')),
  IcoUploadFile: lazy(() => import('./IcoUploadFile')),
  IcoKebab: lazy(() => import('./IcoKebab')),
  IcoShareOptions: lazy(() => import('./IcoShareOptions')),
  IcoFilter: lazy(() => import('./IcoFilter')),
  IcoWarningSign: lazy(() => import('./IcoWarningSign')),
};
