import { Link, LinkParams, LinksResponse } from 'types/links.types';

import RestApiService from '.';
import { Collection } from 'types/api.types';

export default {
  getLinks(this: RestApiService, params?: LinkParams) {
    return this.api.get<Collection<LinksResponse['data']>>(`/api/link-library/links`, {
      params,
    });
  },
  getLinkById(this: RestApiService, id: number) {
    return this.api.get<Link>(`/api/link-library/links/${id}`);
  },
  postFeedbackLinkById(this: RestApiService, linkID: number, feedback: string) {
    return this.api.post<Link>(`/api/link-library/links/${linkID}/feedback`, {
      feedback,
    });
  },
};
