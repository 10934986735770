import { SearchParams, SearchResponse, SearchResult } from 'types/search.types';

import RestApiService from '.';
import { Collection } from 'types/api.types';

export default {
  search(this: RestApiService, params: SearchParams) {
    return this.api.get<Collection<Array<SearchResult>>>(`/api/search`, { params });
  },
};
