import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { Href } from 'types/api.types';
import { Topic } from 'types/topics.types';

const fetchTopics = async (href: string) => {
  const { data: response } = await apiService.api.get<Topic>(href);
  return { ...response, href };
};

type TopicQueryOptions = Omit<
  UseQueryOptions<Topic, Error, Topic, ReturnType<typeof QueryKeys.topics.byId>>,
  'queryKey' | 'queryFn'
>;

export const useTopics = (activities: Array<Href>, config: TopicQueryOptions = {}) => {
  const { locale } = useSelectedLocale();
  const queries = useQueries({
    queries: activities.map((activity) => ({
      ...config,
      queryKey: QueryKeys.topics.byId(activity.href, locale),
      queryFn: () => fetchTopics(activity.href),
      staleTime: Duration.FIVE_MIN,
      enabled: !!activity.href,
    })),
  });

  const topics = useMemo<Array<Topic>>(
    () =>
      queries
        .map((query) => {
          if (!query.data) return null;

          return { ...query.data } as Topic;
        })
        .filter(Boolean) as Array<Topic>,
    [queries],
  );

  const isLoading = useMemo(
    () => !!activities.length && queries.some((item) => item.isLoading),
    [queries, activities],
  );

  return {
    topics: topics.sort((a, b) => a.position - b.position),
    queries,
    isLoading,
  };
};
